import 'bootstrap/dist/js/bootstrap.bundle.min'
import '@vuepic/vue-datepicker/dist/main.css'
import '@/assets/css/main.css'
import '@/assets/css/picke.scss'

import { createApp } from 'vue'
import { vMaska } from "maska"

import App from './App.vue'
import router from './router'
import VueDatePicker from '@vuepic/vue-datepicker';

const app = createApp(App)

app.use(router)
app.directive('maska', vMaska)
app.component('VueDatePicker', VueDatePicker);

app.provide('sysapp', {
    name: import.meta.env.VITE_APP_NAME ?? 'Busca Ativa',
    desc: import.meta.env.VITE_APP_DESC ?? 'Monitoramento Escolar Araripe',
    copy: import.meta.env.VITE_APP_COPY ?? 'OctusPi HoD - Dev 2024',
    url: import.meta.env.VITE_APP_URL ?? '',
    version: '1.0.9 Beta'
  })

app.mount('#app')
