import { ref } from 'vue'

const key_store = 'organ'+import.meta.env.VITE_APP_KEY
const organ = ref(localStorage.getItem(key_store))

function setOrgan(organValue){
  const str_value = JSON.stringify(organValue)
  localStorage.setItem(key_store, str_value)
  organ.value = str_value
}

function getOrgan(){
  try {
    return JSON.parse(organ.value);
  } catch (e) {
    console.log('Fail parse string to JSON')
    return null
  }
}

function clear(){
  localStorage.removeItem(key_store)
}

export default {
  setOrgan,
  getOrgan,
  clear
}