function load(status = true){
    const element = document.getElementById('load-wall')
    if (element) {
        status ? element.classList.remove('d-none') : element.classList.add('d-none')
    }
}

function dateNow() {
    return (new Date()).toLocaleDateString()
}

function randCode(len = 12) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let final = ""

    for (let i = 0; i < len; i++) {
        final += chars[Math.floor(Math.random() * chars.length)]
    }

    return final
}

function getTxt(objs, key_subject, key_search = 'id', key_txt = 'title'){
    if(objs){
        const  f = objs.find(o => o[key_search] == key_subject)
        return f ? f[key_txt] : '*****'
    }

    return '*****'
    
}

function truncate(str, len = 100) {
    if (str == null) {
        return null
    }
    
    if (str.length <= len) {
        return str
    }

    return str.slice(0, len - 3) + "..."
}

function stripHTML(str) {
    const parser = new DOMParser().parseFromString(str, 'text/html')
    return parser.body.textContent
}

function dateProtocol(pivot, separator = '') {
        if (!pivot) {
            return null
        }
        
        const d = new Date();

        const date = (
            d.getDay().toString().padStart(2, '0')
            + d.getMonth().toString().padStart(2, '0')
            + d.getFullYear().toString().padStart(4, '0'))

        const mili = d.getMilliseconds().toString().padStart(4, '0')
    
        return String(pivot).padStart(3, '0') + separator + date + separator + mili
}

function reduceArrays(a, b, k = 'id') {
    const map = new Map();

    if (Array.isArray(a)) {
        a.forEach(item => {
            map.set(item[k], item)
        })
    }
    
    if (Array.isArray(b)) {
        b.forEach(item => {
            map.set(item[k], item)
        })
    }

    return Array.from(map.values())
}

function hideCPF(cpf) {
    const subcpf = cpf.substring(3, 12);

    return subcpf.toString().padStart(12, '*').padEnd(14, '*')
}

function find(data, search, subject = 'id'){
    if (Array.isArray(data)) {
        return data.find(o => o[subject] === search)
    }
    return null
}

function areObjectsEqual(obj1, obj2) {
    // Se os objetos são estritamente iguais, são idênticos
    if (obj1 === obj2) return true;

    // Se algum dos objetos não for do tipo 'object' ou for null, são diferentes
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    // Obtenha as chaves de ambos os objetos
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Se o número de chaves for diferente, os objetos não são iguais
    if (keys1.length !== keys2.length) {
        return false;
    }

    // Verifique se todas as chaves e seus valores são iguais
    for (const key of keys1) {
        // Verifica se ambos os objetos têm a mesma chave e valores equivalentes
        if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export default {
    load,
    dateNow,
    randCode,
    getTxt,
    truncate,
    stripHTML,
    dateProtocol,
    reduceArrays,
    hideCPF,
    find,
    areObjectsEqual
}