import { ref } from 'vue'
import axios from 'axios'
import organ from './organ'

const key_store = 'user'+import.meta.env.VITE_APP_KEY
const user = ref(localStorage.getItem(key_store))

function setUser(userValue){
  const str_value = JSON.stringify(userValue)
  localStorage.setItem(key_store, str_value)
  user.value = str_value
}

function getUser(){
  try {
    return JSON.parse(user.value);
  } catch (e) {
    console.log('Fail parse string to JSON')
    return null
  }
}

async function isAuthenticated(path){

  const {data} = await axios.get(import.meta.env.VITE_URL_API+path, {
    headers:{
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + getUser()?.token,
      'X-Custom-Header-Organ': organ.getOrgan()?.id
    }
  })

  return data;
  
}

function clear(){
  localStorage.removeItem(key_store)
}

export default {
  setUser,
  getUser,
  isAuthenticated,
  clear
}